<template>
  <div v-if="user.vaccine" class="pa-4 mt-2 checkin-wrapper">
    <v-card-subtitle class="px-0 py-0 case-number mb-2 grey--text text--darken-1 d-flex justify-space-between" style="text-transform: uppercase">
      <div>Check In Data</div>
      <v-btn
        v-if="parent == 'vaccineScan'"
        outlined
        :disabled="!selectedLocation"
        color="primary"
        small
        class="mb-2"
        elevation="0"
        @click="showConfirmModal = true"
      >
        <v-icon left>mdi-map-marker</v-icon>
        Check In
      </v-btn>
    </v-card-subtitle>

    <template v-if="user.checkInData && user.checkInData.length > 0">
      <div v-for="checkin in user.checkInData" :key="checkin.id" class="mb-1">
        <v-divider class="mb-1" />
        <div>
          <v-icon left small class="me-0">mdi-calendar</v-icon>
          {{ formatDateTime(checkin.insertDate) }}
        </div>
        <div>
          <v-icon left small class="me-0">mdi-map-marker</v-icon>
          {{ checkin.location.name }}
        </div>
      </div>
    </template>
    <div v-if="user.checkInData && user.checkInData.length == 0">
      <v-alert color="error" dark v-if="parent == 'vaccineScan'" dense class="body-2 mt-1 mb-0" style="width: 100%">
        <v-icon left> mdi-alert-circle</v-icon> User has no checkins
      </v-alert>
      <div class="caption" v-else>No Check ins yet</div>
    </div>
    <v-progress-linear v-else-if="!user.checkInData" indeterminate color="primary" class="mt-1"></v-progress-linear>

    <CheckinConfirmModal
      v-if="showConfirmModal"
      :users="users"
      :user="user"
      :location="locations[selectedLocation - 1]"
      :showConfirmModal="showConfirmModal"
      :box="false"
      @setCheckinData="setCheckinData"
    />
  </div>
</template>

<script>
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import CheckinConfirmModal from '@/views/VaccineViews/VaccineCheckinScanPage/components/CheckinConfirmModal.vue';

export default {
  mixins: [SharedFunctions],
  props: ['users', 'user', 'parent'],
  components: { CheckinConfirmModal },
  computed: {
    selectedLocation() {
      return parseInt(this.$store.state.vaccinePage.selectedLocation);
    },
    locations() {
      return this.$store.state.vaccine.locations;
    },
  },
  data() {
    return {
      showConfirmModal: false,
    };
  },
  methods: {
    setCheckinData(data) {
      this.$parent.$parent.$parent.$parent.setCheckinData(data);
    },
  },
};
</script>

<style lang="scss">
.checkin-wrapper {
  background: #f9f9f9 !important;
  border: 1px solid darken(#f5f5f5, 8%) !important;
  border-radius: 10px;
}
</style>