<template>
  <v-dialog v-model="showConfirmModal" width="900" scrollable persistent>
    <v-card class="received-form" v-if="user">
      <v-card-title class="headline grey lighten-3 mb-4 grey--text text--darken-1">Checkin user to location</v-card-title>
      <v-card-text class="d-flex pt-2 pb-8 justify-space-between align-center" style="align-items: start">
        <!-- USER -->
        <v-card class="pb-4 info-card align-self-start">
          <v-app-bar class="py-0 mb-2 mt-0 primary white--text title" elevation="0" dense color="primary"
            ><v-icon left color="white">mdi-account </v-icon> User Info</v-app-bar
          >
          <div class="text">
            <v-list-item>
              <v-list-item-content class="my-0 py-0">
                <v-list-item-subtitle>Name & last name</v-list-item-subtitle>
                <v-list-item-title>{{ user.firstname }} {{ user.lastname }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mx-4 my-1" />
            <v-list-item>
              <v-list-item-content class="my-0 py-0">
                <v-list-item-subtitle>Case</v-list-item-subtitle>
                <v-list-item-title>{{ user.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mx-4 my-1" />
            <v-list-item>
              <v-list-item-content class="my-0 py-0">
                <v-list-item-subtitle>Birthday</v-list-item-subtitle>
                <v-list-item-title>{{ formatDate(user.birthDay) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-card>
        <!-- ARROW -->
        <v-avatar class="mx-4" color="primary">
          <v-icon color="white" large>mdi-arrow-right-thick</v-icon>
        </v-avatar>
        <!-- LOCATION -->
        <v-card class="pb-4 info-card align-self-start">
          <v-app-bar class="py-0 mb-2 mt-0 primary white--text title" elevation="0" dense color="primary">
            <v-icon left color="#fff">mdi-map-marker </v-icon> Location Info</v-app-bar
          >
          <div class="text">
            <v-list-item>
              <v-list-item-content class="my-0 py-0">
                <v-list-item-subtitle>Location</v-list-item-subtitle>
                <v-list-item-title>{{ location.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mx-4 my-1" />
            <v-list-item>
              <v-list-item-content class="my-0 py-0">
                <v-list-item-subtitle>Adress</v-list-item-subtitle>
                <v-list-item-title>{{ location.address }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-card>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeModal()" :disabled="submiting">Cancel</v-btn>
        <v-btn
          color="success"
          large
          @click="submitScan()"
          :dark="!submiting"
          :disabled="submiting"
          :loading="submiting"
          class="elevation-0"
          ref="submitBtn"
        >
          Submit
          <v-icon right>mdi-send</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SharedFunctions from '@/mixins/SharedFunctions.vue';

export default {
  mixins: [SharedFunctions],
  props: ['users', 'user', 'box', 'showConfirmModal', 'location'],
  data() {
    return {
      submiting: false,
    };
  },
  methods: {
    closeModal() {
      this.$parent.showConfirmModal = false;
    },
    async submitScan() {
      if (this.alreadyCheckedInToday()) {
        this.$store.commit('SET_SNACKBAR', { open: true, text: 'User already checked in today', color: 'error' });
        return;
      }

      const payload = {
        location: { id: this.location.id },
        patientCase: {
          id: this.user.patientCaseId,
        },
      };

      try {
        this.submiting = true;
        //checkin link
        const checkedInSuccessfully = await this.$store.dispatch('checkInUserVaccine', payload);
        if (!checkedInSuccessfully) {
          this.submiting = false;
          return;
        }
        //get checkin data
        const checkinData = await this.$store.dispatch('getCheckInDataUser', this.user.patientCaseId);
        //get index seleted user
        let index = this.users
          .map((user) => {
            return user.patientCaseId;
          })
          .indexOf(this.user.patientCaseId);
        //sent data to parent to update display
        this.$emit('setCheckinData', { index: index, checkinData: checkinData });
      } catch (err) {
        let payload = {
          open: true,
          text: 'Cannot reload data, please refresh page',
          color: 'error',
        };
        this.$store.commit('SET_SNACKBAR', payload);
      } finally {
        this.submiting = false;
        this.closeModal();
        let payload = {
          open: true,
          text: 'User successfully checked in',
          color: 'success',
        };
        this.$store.commit('SET_SNACKBAR', payload);
      }
    },
    alreadyCheckedInToday() {
      if (this.user.checkInData) {
        let alreadyCheckedInToday = false;
        for (let i = 0; i < this.user.checkInData.length; i++) {
          const element = this.user.checkInData[i];
          if (this.isToday(element.insertDate)) {
            alreadyCheckedInToday = true;
            break;
          }
        }
        return alreadyCheckedInToday;
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.info-card {
  flex: 1 1 0;
  width: 0;
}
</style>
