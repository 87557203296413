<template>
  <v-card class="main-card" outlined>
    <v-card-title class="bg-light-primary pb-0">
      <v-row justify="start">
        <v-col sm="6">
          <v-form v-on:submit.prevent="scan(scannedInput)" autocomplete="off">
            <v-text-field
              ref="scanText"
              autocomplete="off"
              id="scanTextInput"
              outlined
              :hint="hint"
              @focus="readyToScan = true"
              @blur="readyToScan = false"
              :rules="[readyToScan || disableScanInput ? true : 'Place cursor inside text field before scanning']"
              persistent-hint
              background-color="white"
              v-model="scannedInput"
              v-bind:value="scannedInput"
              append-icon="mdi-qrcode-scan"
              :label="'QR-code / AZV barcode'"
              single-line
              :disabled="scanInputLoading || disableScanInput"
              :loading="scanInputLoading"
            ></v-text-field>
          </v-form>
        </v-col>
        <transition name="slide-right">
          <v-col sm="6" class="mt-0">
            <div style="margin-top: 6px">
              <v-btn
                large
                @click="$parent.showConfirmModal = true"
                color="success"
                :dark="!$parent.saveButtonDisabled"
                class="me-4 px-8"
                :disabled="$parent.saveButtonDisabled"
              >
                <v-icon left>mdi-content-save</v-icon> Save
              </v-btn>
              <ScanInputCardCancelModal @cancelScan="cancelScan" :disableScanInput="disableScanInput" v-if="$parent.users.length > 0" />
            </div>
            <div class="mt-2">
              <div class="caption mt-0 error--text font-weight-medium" v-if="$parent.locationSelectedMesg">- {{ $parent.locationSelectedMesg }}</div>
              <div class="caption mt-0 error--text font-weight-medium" v-if="$parent.boxSelectedMesg">- {{ $parent.boxSelectedMesg }}</div>
              <div class="caption mt-0 error--text font-weight-medium" v-if="$parent.nurseSelectedMesg">- {{ $parent.nurseSelectedMesg }}</div>
            </div>
          </v-col>
        </transition>
      </v-row>
    </v-card-title>
  </v-card>
</template>

<script>
import ScanInputCardCancelModal from '@/components/generic/ScanInputCardCancelModal.vue';
import SharedFunctions from '@/mixins/SharedFunctions.vue';

export default {
  mixins: [SharedFunctions],
  props: ['disableScanInput', 'caseId'],
  components: {
    ScanInputCardCancelModal
  },
  data() {
    return {
      //scannedInput: '^^3062ce93-97b9-422b-acda-8c24788a2097^',
      scannedInput: null,
      scanInputLoading: false,
      readyToScan: false
    };
  },
  computed: {
    hint() {
      return this.readyToScan ? 'Ready to Scan' : null;
    }
  },
  mounted() {
    this.prefillScannedInput();
  },
  methods: {
    async scan(input) {
      if (!input) return;
      this.scanInputLoading = true;
      const codeType = this.getCodeType(input);
      let subjectData = null;
      if (codeType.type == 'VISITOR_QR') {
        subjectData = await this.$store.dispatch('getVisitorSubjectData', codeType.caseId);
        if (subjectData) {
          this.$parent.displaySubjectData(subjectData, codeType.caseId);
        } else {
          this.scanInputLoading = false;
          this.$parent.focusScanInput();
        }
      } else if (codeType.type == 'AZV_NUMBER') {
        const subjectDataAzv = await this.$store.dispatch('getSubjectDataAzvNumber', codeType.azvNumber);
        if (subjectDataAzv.length == 0) {
          this.displayScanError('No results under this AZV number');
          return;
        } else if (subjectDataAzv.length > 1) {
          let subjectDataAzvMultiple = [];
          for await (let subject of subjectDataAzv) {
            let subjectData = await this.$store.dispatch('getPatientCase', subject.id);
            if (!subjectData.vaccine) subjectData.vaccine = await this.$store.dispatch('getVaccinePatientCase', subject.id);

            subjectDataAzvMultiple.push(subjectData);
          }
          this.$parent.displaySubjectData(subjectDataAzvMultiple, null);
          this.displayScanError('Multiple users detected with this AZV number. Please verify with user and select the right user account', 'warning');
        } else {
          subjectData = await this.$store.dispatch('getPatientCase', subjectDataAzv[0].id);
          let scannedInput = subjectDataAzv.length > 1 ? null : subjectDataAzv[0].id;
          this.$parent.displaySubjectData([subjectData], scannedInput);
        }
      }

      this.scanInputLoading = false;
      this.scannedInput = null;
    },
    cancelScan() {
      this.$parent.loadProgress = false;
      this.$parent.resetAllData();
      setTimeout(() => document.getElementById('scanTextInput').focus(), 100);
    },
    getCodeType(input) {
      if (input.substring(0, 2) === '^^' && input.substring(input.length - 1) === '^') {
        const trimmedInput = input.substr(2);
        const caseId = trimmedInput.substring(0, trimmedInput.length - 1);
        this.$parent.testTubeMessage = null;
        return { type: 'VISITOR_QR', caseId: caseId };
      } else if (this.validateAzvNumber(input.trim())) {
        return { type: 'AZV_NUMBER', azvNumber: input.trim() };
      } else {
        this.displayScanError('Invalid scan input');
        return { type: null };
      }
    },
    displayScanError(mesg, type = 'error') {
      let payload = {
        open: true,
        text: mesg,
        color: type
      };
      this.$store.commit('SET_SNACKBAR', payload);

      this.scanInputLoading = false;
      this.scannedInput = null;
      this.$parent.focusScanInput();
    },
    prefillScannedInput() {
      if (this.caseId) {
        this.scannedInput = '^^' + this.caseId + '^';
        this.scan(this.scannedInput);
      }
    }
  }
};
</script>

<style lang="scss">
.main-card {
  .small-text {
    font-size: 12px;
    top: -9px;
    padding: 0;
    position: relative;
    font-weight: 500;
    color: grey;
  }
}
</style>
